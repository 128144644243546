@import "./vars";
@import "./basic";

@import "./ui/app";
@import "./ui/general";
@import "./ui/severity";
@import "./ui/sidebar";
@import "./ui/themes";
@import "./ui/circularProgress";

@import "./components/video";

@import "./pages/eventsBySerial";

.eventsInterface {
  padding-left: 16px;
  max-width: 1400px;
  margin: 0 auto; }

.eventsInterface-header {
  display: flex;
  align-items: center;
  margin: 24px 0 16px;

  .title {
    font-size: $fontSizeMedium;
    font-weight: 500;
    margin-left: 32px;
    text-transform: uppercase; } }
