.systemSettings {
  height: 100%;

  .card {
    margin-right: 16px;
    margin-bottom: 16px;
    display: flex;
    flex-flow: column; } }

.systemSettings-bar {
  .tabs {
    box-shadow: none; } }
