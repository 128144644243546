@import "../vars";

.report {
  padding: 16px;
  min-width: 242px;
  max-height: 70vh;
  overflow: scroll;

  .title {
    margin-bottom: 12px; }

  .definition-detail {
    font-size: $fontSizeSmall; }

  .hint {
    margin: 24px 0 4px; } }

.report-download {
  .btn:not(:first-child) {
    margin-left: 8px; } }
