@import "../vars";

.connection-status {
    margin: 0 -32px;
    table {
        width: 100%;
        font-weight: 500;
        thead {
            color: var(--color-text-light);
            font-size: $fontSizeSmall;
            text-transform: uppercase; }
        tbody {
            font-size: $fontSizePrimary; }
        tr {
            td.connection-block-title,th:first-child {
                padding-left: 32px; }
            td:last-child {
                padding-right: 32px; }
            td,th {
                vertical-align: middle;
                text-align: left;
                height: 44px;
                border-bottom: 1px solid var(--color-border); } } } }
