.locations-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.locations-row {
  width: 50%;
  padding: 0 24px;

  .form-field {
    max-width: 240px; } }
