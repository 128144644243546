@mixin interactiveUserCardSettings {
  .card-header {
    text-transform: none; } }

.interactiveUserBlind {
  @include interactiveUserCardSettings;

  .spinner-container {
    margin: 100px; } }

.interactiveUserBlind-table {
  margin: 64px -32px 40px;

  .table-header {
    border-bottom: 1px solid var(--color-border-table); }

  .table-header-cell {
    padding-bottom: 14px; }

  .table-content {
    box-shadow: none; }

  .table-cell:first-child .table-cell-content,
  .table-header-cell:first-child {
    padding-left: 32px; }

  .table-row:last-child {
    border-bottom: 1px solid var(--color-border-table); }

  .table-cell:last-child {
    .table-cell-content {
      padding-left: 10px; } } }

.interactiveUserBlind-addPanel {
  margin: auto;
  display: block; }

.interactiveUserAddPanel {
  @include interactiveUserCardSettings;

  .search-bar {
    background-color: var(--color-background-gray200);
    box-shadow: none;

    ::placeholder {
      color: var(--color-text-light); } }

  .pager {
    position: relative;
    right: -17px;

    .btn-content {
      font-weight: normal; }

    > .btns {
      box-shadow: none;

      .btn {
        background-color: var(--color-background-gray200); } } } }
