.cols {
  display: flex;
  flex-flow: row; }

.cols--stretch > .col {
  flex: 1 1 50%;
  width: 50%; }

.col:not(:first-child) {
  margin-left: 16px; }

.col:not(:last-child) {
  margin-right: 16px; }
