.icon-rotate {
  animation: icon-rotate 1s linear infinite; }

@keyframes icon-rotate {
  from {
    transform: rotate(0); }

  to {
    transform: rotate(360deg); } }

@keyframes icon-heartbeat {
  0% {
    transform: scale( .9 ); }
  10% {
    transform: scale( 1 ); }
  40% {
    transform: scale( .9 ); }
  50% {
    transform: scale( 1 ); }
  80%, 100% {
    transform: scale( .9 ); } }

.icon-heartbeat {
  animation: icon-heartbeat 2s infinite; }
