.panelInfo {
  > .scroll {
    padding-right: 12px;
    padding-bottom: 12px; }

  > .sidebar {
    width: 35%;
    flex-shrink: 0;

    > .scroll {
      padding-top: 12px;
      height: auto;
      max-height: 100%;
      flex-grow: 0; } }

  .card {
    margin: 16px 16px 16px 0;
    flex-grow: 1; }

  .map {
    margin-bottom: 16px; } }

.panelInfo-notification {
  margin: 64px 32px 32px;
  display: flex;
  align-items: center;
  max-width: 500px;
  line-height: 1.5;

  .circle {
    background-color: var(--color-warning50);
    margin-right: 20px; } }

.menu-item--toggle {
  padding-top: 4px;
  padding-bottom: 4px;

  .form-field--togglebox {
    line-height: 32px; } }


.panels-customer-info {
  width: 200px; }
