@import "../vars";

.hidden {
  display: none !important; }

.block {
  margin-top: 32px;

  > .title, > .title-group {
    margin-bottom: 16px; } }


.hr {
  margin: 16px 0;
  border-top: 1px solid var(--color-border-light); }

.hint {
  font-weight: 500;
  font-size: $fontSizeSmall;
  color: var(--color-primary);
  text-transform: uppercase; }

.hint--gray {
  color: var(--color-text-secondary); }

.hint-delim {
  height: 15px;
  border-left: 1px solid currentColor;
  width: 0;
  display: inline-block;
  margin: 0 8px;
  vertical-align: -3px;
  color: var(--color-text);
  opacity: $opacityTextSuperLight; }

.error {
  height: 100%;
  min-height: 200px;
  display: flex;
  margin: 0 auto;
  max-width: 500px;
  padding: 24px;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  text-align: center; }

.error-title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 12px;
  opacity: .4; }

.error-message:not(:last-child) {
  margin-bottom: 24px; }

.empty-message {
  text-align: center;
  color: var(--color-text-secondary);
  margin: 0 auto;
  max-width: 400px;
  padding: 24px; }

.title-group {
  display: flex;
  justify-content: space-between; }

.title {
  font-size: $fontSizeMedium;
  font-weight: 500; }

.title--medium {
  font-size: 16px; }

.title--large {
  font-size: $fontSizeLarge;

  .definition-detail {
    font-size: $fontSizeMedium; } }

.title--margin {
  margin: 16px 0; }

.title--big {
  font-size: $fontSizeBig;

  .definition-detail {
    font-size: $fontSizeMedium;
    margin-left: 20px; } }

.title--secondary {
  color: var(--color-text-secondary); }

.title--cols {
  margin: 64px 0 12px;
  font-size: $fontSizeMedium;

  + .cols > .form-section {
    margin-top: 0; } }

.title-hint {
  margin-left: 12px;
  font-weight: normal;
  font-size: $fontSizeSmall;
  color: var(--color-text-secondary);
  display: inline; }

.primary {
  color: var(--color-primary); }

.colons {
  .colon:not(:last-child):after {
    content: ', '; } }

.simple-list {
  padding-left: 24px;
  margin: 8px 0; }

ol.simple-list {
  list-style-type: decimal; }

ul.simple-list {
  list-style-type: disc; }
