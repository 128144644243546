@import "~ipmp-react-ui/themes/themes";
@import "~ipmp-react-ui/themes/sanMarino";
@import "~ipmp-react-ui/themes/valencia";

.theme--dark {
  .app-aside-logo {
    color: white; }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(white, .2); }

  .login {
    background: #00213F linear-gradient(to bottom, #00213F, #0B163F, #10113F); }

  .about .card-header {
    background: #00539E linear-gradient(45deg, #10113F, #00213F); }

  .device-image {
    opacity: .7; }

  .device--disabled .device-image {
    opacity: .4; } }

.theme--valencia {
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px lighten(#2E2C2B, 4) inset !important;
    -webkit-text-fill-color: white !important; }

  //.login
 }  //background: black linear-gradient(to bottom, rgba($themeValenciaColorPrimary, .2), rgba(red, .05))
