@import "../vars";

.permissions {
  .form-row {
    height: 48px;
    padding: 0 8px; }

  .form-row-content {
    width: 200px;
    flex-grow: 0;
    flex-shrink: 0; }

  .form-row-label {
    margin-right: 16px; }

  .form-row-label + .form-row-content:last-child {
    width: 400px; } }

.permissions-sublist {
  margin-left: 20px; }

.card-content > .permissions-group {
  &:not(:first-child) {
    margin-top: 36px; } }

.permissions-group {
  position: relative;
  transition: background-color $time; }

.permissions-group--label {
  text-transform: uppercase;
  font-weight: 500;
  color: var(--color-text-light);
  padding-left: 20px;
  cursor: pointer; }

.permissions-group--hasAllowed {
  .permissions-group--label {
    font-weight: bold;
    color: var(--color-text); } }

.permissions-tooltip {
  display: inline-block;
  padding-left: 8px;
  cursor: help; }

.permissions-group-icon {
  position: absolute;
  left: 8px;
  top: 50%;
  color: var(--color-text);
  transform: translateY(-50%) rotate(-90deg); }

.permissions-group-icon--active {
  transform: translateY(-50%); }
