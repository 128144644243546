@import "../vars";

.userInfo {
  position: absolute;
  top: 6px;
  padding: 2px;
  right: 12px;
  z-index: 6;

  .ava {
    cursor: pointer; } }

.userInfo-menu {
  padding: 0;

  .menu-item-icon {
    width: 18px;
    height: 18px; }

  .menu-item {
    height: 60px;
    line-height: 60px;
    border-top: 1px solid var(--color-border-light);

    &:last-child {
      border-radius: 0 0 2px 2px; } } }

.userInfo-user {
  white-space: nowrap;
  min-width: 260px;
  display: flex;
  padding: 16px 24px; }

.userInfo-name {
  font-weight: 500;
  font-size: $fontSizeMedium;
  margin-bottom: 8px; }

.userInfo-details {
  margin-top: 4px;
  opacity: $opacityTextSecondary; }

.userInfo-ava {
  margin-right: 18px; }

.userInfo-help {
  color: var(--color-primary);
  font-size: $fontSizeSmall;
  text-decoration: underline; }
