.circularProgress {
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px currentColor;
    border-radius: 50%; }


.circularProgress--intermediate {
    animation: rotate 5s infinite; }

@keyframes rotate {
    from {
        transform: rotate(0deg); }
    to {
        transform: rotate(360deg); } }
