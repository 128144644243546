.panelFirmware {
  .bar {
    margin: 12px 16px; } }

.panelFirmware-search {
  margin-bottom: 16px;
  padding-right: 12px; }

.panelFirmware-content {
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 16px;

  &:first-child {
    padding-top: 16px; } }
