@import "../vars";

.applications-icon {
  margin-right: 4px; }

.application {
  font-weight: bold;
  font-size: $fontSizeSmall;
  margin: 0 2px;
  opacity: $opacityTextSuperLight; }

.application--enabled {
  opacity: 1; }
