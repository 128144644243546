.batchInfo-table {
  margin-bottom: 16px;
  border-top: 1px solid var(--color-border-table);
  border-bottom: 1px solid var(--color-border-table);

  .table-content {
    //background-color: var(--color-background)
    box-shadow: none;
    border-radius: 0;
    max-height: calc(100vh - 240px); }

  .table-cell:first-child .table-cell-content {
    padding-left: 32px; } }
