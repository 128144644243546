$batchWidth: 324px;

.batchInfo {
  width: $batchWidth;
  box-shadow: $boxShadowAppAsside;
  background: var(--color-background-card);
  z-index: 4;
  position: absolute;
  height: 100%;
  animation: openBatchInfoLarge $time $easeMd;
  transform: translate3d($mainAssideWidthLarge, 0, 0); }

.batchInfo-title {
  height: 72px;
  padding: 24px;
  font-size: 17px;
  font-weight: bold;
  border-bottom: 1px solid var(--color-border); }

.btn.batchInfo-close {
  position: absolute;
  right: 7px;
  top: 8px;

  .btn-icon {
    color: $colorGray600; } }

.batchInfo-list {
  overflow: auto;
  height: calc(100% - 72px); // header height
  padding-bottom: 16px; }

.batchInfo-list-item {
  min-height: 80px;
  cursor: pointer;
  padding: 16px 16px 16px 24px;
  border-bottom: 1px solid var(--color-border);
  transition: background-color $easeMd $time;

  &:hover {
    background-color: rgba(var(--color-primary), .08); } }

.batchInfo-list-moreBtnHolder {
  display: flex;
  justify-content: center;
  padding: 16px 0;

  .btn {
    width: 66.6%; } }

.batchInfo-list-item-details {
  display: flex;
  margin-bottom: 13px; }

@mixin overflowTooltipStyle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.batchInfo-list-item-details-panel {
  width: 35%;
  font-weight: 500;
  @include overflowTooltipStyle; }

.batchInfo-list-item-details-duration {
  width: 65%;
  text-align: right;
  color: $colorGray600;
  font-size: 13px;
  @include overflowTooltipStyle; }

.batchInfo-list-item-status {
  display: flex;
  align-items: baseline; }

.btn.batchInfo-list-item-stopProcess {
  width: 20px;
  height: 20px;
  margin-right: 12px;

  .btn-content {
    height: 20px;
    line-height: 20px; }

  .btn-icon {
    height: 8px;
    width: 8px;
    height: 20px;
    line-height: 20px; } }

@media screen and (max-width: 1280px) {
  .batchInfo {
    animation: openBatchInfoMedium $time $easeMd;
    transform: translate3d($mainAssideWidthMedium, 0, 0); } }

@keyframes openBatchInfoLarge {
  from {
    transform: translate3d($mainAssideWidthLarge - $batchWidth, 0, 0); }
  to {
    transform: translate3d($mainAssideWidthLarge, 0, 0); } }

@keyframes openBatchInfoMedium {
  from {
    transform: translate3d($mainAssideWidthMedium - $batchWidth, 0, 0); }
  to {
    transform: translate3d($mainAssideWidthMedium, 0, 0); } }
