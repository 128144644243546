.remoteInspection-icon--fail {
  color: var(--color-danger); }

.remoteInspection-icon--unknown {
  opacity: 0.5; }

// Panel page calendaric remote inspection
.calendar-header {
  .remoteInspection-btns {
    margin-left: auto; } }

.remoteInspection-rriResultProgress {
  width: 100%;
  height: 24px;

  .progress-value {
    line-height: 24px; } }
