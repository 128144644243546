.configurations {
  max-width: 600px;
  margin: 16px auto;
  height: 100%;

  > .bar {
    margin-bottom: 16px; } }

.configurations-icon {
  color: var(--color-text-secondary);
  width: 20px; }

.configurations-icon--active {
  color: var(--color-primary); }
