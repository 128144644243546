@import "../vars";

.panelStatus {
  color: var(--color-text-secondary); }

.panelStatus-icon {
  width: 24px;
  height: 24px;

  &.success {
    animation: blink $time * 3 ease; } }

.panelStatus-icon--offline {
  color: var(--color-danger); }

.panelStatus-icon--online {
  color: var(--color-success); }

.panelStatus-icon--connected {
  color: var(--color-connected); }

.panelStatus-icon--installed {
  color: var(--color-text-secondary); }

.panelStatus-icon--unknown {
  color: var(--color-disabled); }

@keyframes blink {
  from, to {
    transform: scale(1);
    text-shadow: none; }

  5% {
    transform: scale(1.3);
    text-shadow: 0 0 10px currentColor; } }

.panelStatusLine {
  font-weight: 500;
  height: 36px;
  padding-left: 16px;
  text-transform: uppercase;
  margin-left: 16px;
  border-left: 1px solid var(--color-border);
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start; }

.panelStatusLine--hasTroubles {
  color: var(--color-text-danger); }

.panelStatusLine-message {
  display: block;
  margin-top: 2px;
  font-size: $fontSizeSuperSmall;
  color: var(--color-text-super-light);
  text-transform: initial; }

.panelStatusLine-discovery {
  display: block;
  margin-top: 2px;
  font-size: $fontSizePrimary;
  color: $colorGray600;
  text-transform: initial; }

.panelStatusLine-discovery-progresstext,
.panelStatusLine-discovery-duration {
  color: $colorGray600;
  font-size: $fontSizeSuperSmall;
  vertical-align: middle; }

.panelStatusLine .panelStatusLine-discovery-progressbar {
  vertical-align: middle;
  width: 70px; }
