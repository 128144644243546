.blockedNotificationsHelp {
  width: 600px !important;

  .card-actions {
    text-align: center; } }

.blockedNotificationsHelp-img {
  margin-top: 16px;
  margin-bottom: -16px;
  display: block; }

.blockedNotificationsHelp-text {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 24px; }
