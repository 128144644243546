@import "../vars";

.rri {
  margin-right: 0 !important;

  .sidebar {
    max-width: 650px;
    width: 50%;
    padding-left: 0;
    margin-left: 12px; }

  .table-row .definition {
    display: inline-block; }

  .table-cell:last-child .table-cell-content {
    padding-left: 0; }

  .table-row--active {
    position: relative;

    &:before {
      content: '';
      width: 7px;
      height: 100%;
      position: absolute;
      background-color: var(--color-background-primary);
      mask: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9JzEwMCcgaGVpZ2h0PScxMDAnPjxwYXRoIGQ9Ik0gMTAwLDUwIDAsMTAwIDAsMCB6IiBmaWxsPSJjdXJyZW50Q29sb3IiLz48L3N2Zz4=);
      mask-size: 100% 100%;
      top: 0;
      left: 100%; } } }

[dir="rtl"] .rri {
  .table-row--active:before {
    transform: rotate(180deg); } }

.rri-next {
  background-color: var(--color-background-card50);
  height: 60px;
  display: flex;
  margin-bottom: 12px;
  align-items: center;
  border-radius: 2px;
  margin-right: 16px;
  padding-right: 12px;
  box-shadow: $boxShadowElevation05;

  .rri-status {
    margin: 0 12px;
    opacity: $opacityTextSecondary; }

  .progress {
    margin-right: 12px; } }

.rri-next-date {
  font-weight: 500;
  margin-left: 12px; }

.rri-next-repetition {
  padding-right: 4px;
  padding-left: 12px;
  flex-grow: 1;
  text-align: right;
  color: var(--color-text-super-light); }

.rri-caption {
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal; }

.rri-caption--error {
  color: var(--color-danger); }

.rri-caption--running {
  color: var(--color-primary); }

.rri-caption--failed {
  color: var(--color-danger); }

.rri-caption--pending {
  color: var(--color-disabled); }

.rri-caption--success {
  color: var(--color-success); }

.table-row--active {
  .rri-caption {
    color: currentColor; }

  .rri-caption--pending {
    opacity: $opacityTextSecondary; } }

.rri-progress {
  width: 70px;
  margin-left: auto;
  margin-right: 8px; }

.rri-actions {
  display: flex;
  justify-content: flex-end; }

.rri-action {
  display: inline-block;
  height: 36px;
  width: 34px;
  line-height: 34px;
  text-align: center;

  .calendar-item-icon & {
    height: 24px;
    width: 24px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center; } }

.rri-action-icon {
  vertical-align: middle;
  max-height: 16px; }

.rri-action-icon--viewed {
  transform: translate(2px, 1px); }

.rri-action-icon--notViewed {
  transform: translate(-1px, 0); }

.rri-action-icon--sent {
  transform: translate(4px, 0); }

.rri-schedule-radio {
  display: block; }

.rri-schedule-popdown {
  width: 200px;
  height: 200px; }

.rri-results {
  height: calc(100% - 50px); }

.rri-results-content {
  max-height: 100%;
  overflow-y: auto; }

.rri-result {
  position: relative;
  padding: 0 16px; }

.rri-result-content {
  font-weight: 500;
  padding-bottom: 16px;
  padding-left: 24px; }

.rri-result-header {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 50px;
  position: relative; }

.rri-result-header-toggle {
  width: 24px; }

.rri-result-header-toggle-icon {
  transition: transform $time $easeOutSine;
  transform: rotate(-90deg); }

.rri-result-header-toggle-icon--active {
  transform: none; }

.rri-result-title {
  font-size: $fontSizePrimary;
  font-weight: 500;
  min-width: 30%; }

.rri-result-title--passed {
  color: var(--color-success); }

.rri-result-title--failed {
  font-size: $fontSizeMedium;
  color: var(--color-danger); }

.rri-result-title--unavailable {
  color: var(--color-disabled); }

.rri-result-range {
  color: $colorGray500;
  font-size: $fontSizePrimary;
  padding-left: 30px; }

.rri-result-actions {
  height: 50px;
  position: relative;
  border-bottom: 1px solid var(--color-border);
  line-height: 50px;
  text-align: center; }

.rri-result-nav.btn {
  top: 50%;
  transform: translateY(-50%);
  position: absolute; }

[dir="rtl"] .rri-result-nav {
  .btn-content {
    svg {
      transform: rotate(180deg); } } }

.rri-result-nav--prev.btn {
  left: 8px; }

.rri-result-nav--next.btn {
  right: 8px; }

.rri-result-details-row {
  min-width: 20%;
  padding: 8px 24px; }

.rri-result-details-line {
  padding: 8px 0; }

.rri-result-details-row:first-child {
  padding-left: 0; }

.rri-result-details-time {
  color: var(--color-text-secondary);
  font-weight: normal;
  white-space: nowrap;
  font-size: $fontSizeSmall; }

.rri-bar {
  margin: 12px 12px 16px 0;

  .form-field--datepicker {
    width: 250px;
    margin-left: 12px; } }

.rri-nearest {
  display: flex;
  align-items: center; }

.blindModalPanelRemoteInspection {
  .rri-nearest {
    margin: 24px 0 16px; }

  .rri-result {
    padding: 0; } }
