.panelPage-content {
  > .bar {
    margin: 8px 12px 12px 12px;
    flex-shrink: 0; }

  > .scroll {
    margin: 12px 12px 12px 0;
    flex-shrink: 1;
    flex-grow: 0;
    height: auto; } }
