.pushBasicConfiguration-header {
  display: flex;
  margin-top: 8px;
  margin-right: 44px; }

.pushBasicConfiguration-btn--goBack {
  margin-right: 12px; }

.pushBasicConfiguration-search {
  width: 100%; }
