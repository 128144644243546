@import "../vars";

.panelRemarks {
  .card-header {
    border-bottom: 1px solid var(--color-border); }

  .card-content {
    flex-shrink: 0;
    display: flex;
    flex-flow: column;
    padding-bottom: 0;
    height: calc(100% - 72px);
    padding-right: 0;
    padding-left: 0; }

  .scroll {
    padding-right: 16px;
    padding-left: 24px; } }

.panelInfo-addRemark {
  padding: 24px;
  display: flex;
  align-items: center;
  position: relative;
  flex-shrink: 0;
  z-index: 1;
  transition: box-shadow $time $easeMd;
  border-top: 1px solid var(--color-border);

  .form-field {
    flex-grow: 1;
    flex-shrink: 0;
    margin-right: 12px; }

  .input-field {
    max-height: calc(50vh - 100px); } }

.panelInfo-remarks-title {
  text-align: center;
  margin-top: 32px;

  &:first-child {
    margin-top: 12px; } }
