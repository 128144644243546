@import "../vars";

.panelState {
  padding-top: 16px;

  .table-row--active {
    position: relative;

    &:before {
      content: '';
      width: 7px;
      height: 100%;
      position: absolute;
      background-color: var(--color-background-primary);
      mask: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9JzEwMCcgaGVpZ2h0PScxMDAnPjxwYXRoIGQ9Ik0gMTAwLDUwIDAsMTAwIDAsMCB6IiBmaWxsPSJjdXJyZW50Q29sb3IiLz48L3N2Zz4=);
      mask-size: 100% 100%;
      top: 0;
      left: 100%; } }

  .definition-detail {
    font-size: $fontSizeSmall;
    font-weight: 500;
    text-transform: uppercase; } }

[dir="rtl"] .panelState {
  .table-row--active:before {
    transform: rotate(180deg); } }

.panelState-partition {
  padding: 8px; }

.panelState-readyState {
  background-color: rgba(black, .07);
  color: var(--color-text-secondary);

  .circle-icon {
    width: 16px;
    height: 16px; } }
