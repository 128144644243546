@import "../vars";

.app {
  height: 100%;
  display: flex; }

.app-aside {
  position: relative;
  z-index: 5;
  flex-shrink: 0;
  width: $mainAssideWidthLarge;
  height: 100%;
  background: var(--color-background-card);
  box-shadow: $boxShadowAppAsside;
  display: flex;
  flex-flow: column; }

.app-aside-content {
  flex-grow: 1;
  overflow: hidden;

  &:hover {
    overflow-y: auto; } }

.app-aside-logo {
  margin: 20px 36px 32px;
  flex-shrink: 0;
  cursor: pointer; }

.logo {
  text-align: left;
  display: block;
  font-weight: bold;
  font-size: 28px;
  line-height: 0.9;
  letter-spacing: -1.5px; }

.logo--custom {
  overflow: hidden;
  max-width: 100%;
  height: 50px;

  > svg {
    display: block;
    max-width: 100%;
    height: 50px; } }

.logo-version {
  opacity: $opacityTextSecondary;
  font-size: 11px;
  vertical-align: .5em;
  letter-spacing: 0;
  font-weight: 500;
  margin-left: 5px; }

.app-aside-title {
  font-size: $fontSizeSmall;
  color: var(--color-text-secondary);
  text-transform: uppercase;
  font-weight: 500;
  margin: 12px 12px 0;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.app-aside-title-icon {
  max-width: 18px;
  height: 18px;
  cursor: pointer; }

.about {
  width: 400px !important;

  .card-header {
    background: $colorJCBlue linear-gradient(45deg, #2A2A9D, $colorJCBlue);
    color: white;
    text-transform: none;
    padding: 36px 48px;
    border-radius: 2px 2px 0 0;

    .logo--custom {
      max-width: 200px; } }

  .card-close {
    color: white; }

  .card-content {
    margin-top: 36px;
    padding-left: 48px;
    padding-bottom: 24px; } }

.card-about-license {
  padding-bottom: 0;

  .block:first-child {
    margin-top: 0; }

  .block + .block {
    margin-top: 40px; }

  .table {
    margin-left: -32px;
    margin-right: -32px; }

  .table-cell:first-child .table-cell-content {
    padding-left: 32px; } }


.card-about-servernotes {
  padding: 0 32px;
  height: 600px;

  .block:first-child {
    margin-top: 0;
    .about-info:first-child {
      margin-top: 0; } }

  .servernotes {
    white-space: pre-wrap;
    margin-top: 48px; } }


.about-info {
  margin-top: 16px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  white-space: nowrap;

  .hint {
    font-size: 11px; } }

@media screen and (max-width: 1280px) {
  .app-aside {
    width: $mainAssideWidthMedium; }

  .nav-link-icon {
    display: none; }

  .nav-link-content {
    padding-left: 32px !important; } }
