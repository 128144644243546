@import "../vars";

.logVar {
  display: inline-block;
  padding: 0 4px;
  border-radius: 2px;
  background: var(--color-background-black10); }

.logVar--trigger {
  cursor: pointer; }

.logVar--empty {
  color: var(--color-disabled);
  font-weight: normal; }

.logVar-more {
  margin-left: .5em;
  opacity: $opacityTextSecondary;
  font-size: $fontSizeSmall; }

.logVar-more-icon {
  width: 8px; }

.logVar-item {
  font-weight: 500;
  white-space: nowrap;

  &:not(:first-child) {
    margin-top: 12px; } }
