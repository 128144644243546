@import "../vars";

.communication {
  border: 2px solid currentColor;
  display: inline-block;
  width: 18px;
  height: 18px;
  line-height: 16px;
  border-radius: 2px;
  font-size: $fontSizeSmall;
  text-align: center;
  font-weight: bold;
  color: var(--color-text-secondary);

  &:not(:first-child) {
    margin-left: 8px; } }

.communication--online {
  color: var(--color-success); }

.communication--connected {
  color: var(--color-connected); }

.communication--offline {
  color: var(--color-danger); }

.communication--none {
  color: var(--color-disabled); }
