@import "../vars";

.video {
  user-select: none;

  &:hover .video-nav {
    opacity: .75; } }

.video-player {
  background-color: black;
  border-radius: 2px 2px 0 0;
  position: relative;
  user-select: none;

  .error {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    color: white; }

  .spinner-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    color: white;
    z-index: 1; }

  &:before {
    content: '';
    display: block;
    padding-top: percentage(400/720); } }

.video-player-icon {
  left: 50%;
  top: 50%;
  color: white;
  opacity: .3;
  width: 32px;
  height: 32px;
  position: absolute;
  transform: translate(-50%, -50%); }

.video-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.video-element {
  max-width: 100%;
  height: 100%;
  display: block; }

.video-element--hidden {
  width: 0;
  height: 0;
  opacity: 0; }

.video-definition {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: baseline; }

.video-actions {
  border-top: 1px solid var(--color-border);
  padding: 8px;

  .btn:not(:last-child) {
    margin-right: 8px; } }

.video-record {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 12px 0 5px;
  border-radius: 50%;
  vertical-align: 1px;
  background-color: var(--color-background-red); }

.video-title {
  font-weight: 500;
  font-size: $fontSizeMedium; }

.video-time {
  margin-top: 4px;
  color: var(--color-text-secondary); }

.video-description {
  color: var(--color-text-secondary);
  font-size: $fontSizeSmall; }

.video-thumb {
  position: absolute;
  width: 80px;
  border-radius: 2px;
  border: 1px solid black;
  box-shadow: 0 0 3px rgba(black, .5);
  margin-left: -40px;
  bottom: 10px;

  &:after {
    content: '';
    border-radius: inherit;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    box-shadow: inset 0 0 0 1px white;
    opacity: .3; } }

.video-thumb--image {
  width: 100%;
  display: block; }

.video-progress {
  padding: 8px 0;
  margin: -8px 0;
  z-index: 2;
  position: relative;

  &:before {
    content: '';
    display: block;
    height: 3px;
    background-color: var(--color-background-gray500); }

  &:hover {
    .video-progress-bar:before {
      transform: scaleY(1.25); }

    &:before {
      transform: scaleY(1.25); }

    .video-progress-knob {
      transform: scale(1); } } }

.video-progress-bar {
  height: 3px;
  min-width: 4px;
  position: absolute;
  left: 0;
  top: 8px;
  width: 100%;

  &:before {
    content: '';
    display: block;
    height: 3px;
    background-color: var(--color-background-red);
    transition: $time $easeMd;
    transition-property: transform; } }

.video-progress-bar--preview {
  transition: none;

  &:before {
    background-color: rgba(white, .5); } }

.video-progress-knob {
  position: absolute;
  z-index: 3;
  top: 50%;
  width: 12px;
  height: 12px;
  right: -6px;
  margin-top: -6px;
  background-color: var(--color-background-red);
  box-shadow: 0 0 2px rgba(black, .5);
  border-radius: 50%;
  transform: scale(0);
  transition: transform $time $easeOutBack; }

.video-nav {
  padding: 16px;
  position: absolute;
  z-index: 2;
  height: 100%;
  top: 0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: .3;
  transition: opacity $time $easeMd;

  &:hover {
    opacity: 1 !important; } }

.video-nav--prev {
  left: 0; }

.video-nav--next {
  right: 0; }

.video-nav--play {
  left: 44px;
  right: 44px;
  opacity: .3; }

.video-nav-icon {
  width: 12px;
  height: 18px; }

.video-nav-circle {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: rgba(black, .3);
  line-height: 70px;
  color: white;
  text-align: center;
  opacity: .75;
  transition: opacity $time $easeMd;

  .video-nav-icon {
    width: 24px;
    height: 32px; } }

.cameraList {
  margin-bottom: 16px; }

.cameraList-items {
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0 -16px 0;
  justify-content: space-between; }

.cameraList-item {
  width: 100px;
  padding: 6px;
  margin-right: 8px;
  margin-bottom: 16px;
  transition: transform $time $easeMd; }

.cameraList-item--active {
  background-color: var(--color-primary);
  color: white;
  border-radius: 2px;
  box-shadow: $boxShadowElevation1;
  transform: translateY(-2px);
  transition: box-shadow $time $easeMd, transform $time $easeMd; }

.cameraList-item--stub {
  padding: 0;
  margin-bottom: 0; }

.cameraList-item-image {
  background-color: black;
  border-radius: 2px;
  height: 75px;
  color: white;
  position: relative;
  margin-bottom: 4px;

  .spinner-container {
    color: inherit;
    transform: scale(.6);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%; } }

.cameraList-item-image-src {
  background-size: cover;
  border-radius: 2px;
  height: 100%;
  opacity: .9; }

.cameraList-item-image-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 22px; }

.cameraList-item-image-icon--notAvailable {
  opacity: .3; }

.cameraList-item-title {
  font-weight: 500;
  display: block; }

.cameraList-item-description {
  margin-top: 4px;
  display: block;
  font-size: $fontSizeSmall;
  opacity: $opacityTextSecondary; }

.video-available-till {
  color: var(--color-text-secondary);
  padding: 32px;
  text-align: center; }

.video-circular {
  vertical-align: -5px;
  margin-right: 8px; }
