@import "vars";

@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&subset=cyrillic,cyrillic-ext,greek,greek-ext,latin-ext,vietnamese');

@font-face {
  font-family: 'text-security-disc';
  src: url('../assets/fonts/text-security-disc.woff') format('woff'), url('../assets/fonts/text-security-disc.ttf') format('truetype'); }

h1, h2, h3, h4, h5, h6 {
  margin: initial; }

html, body, #root {
  height: 100%;
  caret-color: var(--color-primary); }

[dir] body {
  margin: 0;
  min-width: 1000px;
  position: relative;
  background: var(--color-background); }

html, body, input, textarea, select, button {
  color: var(--color-text);
  font: #{$fontSizePrimary}/1.3 Roboto, sans-serif; }

::-webkit-scrollbar {
  width: 6px;
  height: 6px; }

::-webkit-scrollbar-thumb {
  background-color: rgba(black, .2); }

::placeholder {
  color: var(--color-text-light); }

svg {
  display: inline-block;
  vertical-align: middle; }

hr {
  border: none;
  border-top: 1px solid currentColor;
  opacity: $opacityTextSuperLight;
  margin: 16px 0; }

a {
  cursor: pointer;
  color: inherit;
  text-decoration: none; }

.link {
  color: var(--color-primary);
  text-decoration: underline;
  cursor: pointer; }

.empty {
  color: var(--color-text-light); }

.danger {
  color: var(--color-text-danger); }

.warn {
  color: var(--color-text-warning); }

.success {
  color: var(--color-text-success); }

.primary {
  color: var(--color-text-primary); }

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

input, textarea, button {
  background: none;
  border: none;

  &:focus {
    outline: none; } }

.ulist {
  margin: 16px 0;
  line-height: 1.5;
  padding-left: 20px;
  list-style: disc; }

.edit-link {
  cursor: pointer; }

.streamNewEvents {
  margin-left: 16px; }
