@import "../vars";

$height : 36px;

.nav {
  font-size: $fontSizeMedium;
  user-select: none; }

.nav-link-wrapper {
  position: relative; }

.nav-link {
  cursor: pointer;
  display: block;
  height: $height;
  line-height: $height;
  margin-bottom: 8px;
  position: relative;
  transition: background-color $time $easeOutSine, color $time $easeOutSine;

  &:hover {
    background-color: var(--color-background-gray100); } }

.nav-link--active {
  color: var(--color-primary);
  font-weight: 700;

  &, &:hover {
    background-color: var(--color-background-primary-highlight); }

  .nav-link-icon {
    opacity: 1; } }

.nav-link-icon {
  position: absolute;
  left: 36px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  margin-right: 12px;
  opacity: .7;
  transition: opacity $time $ease; }

.nav-link-action {
  position: absolute;
  transform: translateY(-50%);
  padding: 8px;
  top: 50%;
  right: 4px;
  cursor: pointer;
  color: var(--color-text-super-light);
  transition: color $time $ease; }

.nav-link-action-icon {
  display: block;
  width: 10px; }

.nav-link-wrapper--action {
  .nav-link-content {
    margin-right: 20px; } }

.nav-link-wrapper--action:hover {
  .nav-link-action {
    color: var(--color-text-secondary);

    &:hover {
      color: var(--color-danger); } } }

.nav-link-content {
  padding-left: 76px;
  padding-right: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.nav-submenu {
  font-size: $fontSizePrimary;
  margin-bottom: 30px;

  .nav-link {
    margin-bottom: 0; } }

.nav-link-expand {
  position: absolute;
  cursor: pointer;
  left: 4px;
  top: 4px;
  width: 24px;
  opacity: .8;
  border-radius: 2px;
  height: $height - 8px;
  text-align: center;
  line-height: $height - 8px;
  transition: background-color $time $easeOutSine;

  &:hover {
    background-color: rgba(black, .08); } }

.nav-link-expand--active .nav-link-expand-icon {
  transform: rotate(0deg); }

.nav-link-expand-icon {
  transition: transform $time / 2 $easeOutCubic;
  transform: rotate(-90deg); }

.nav-link-coin {
  background-color: var(--color-background-black10);
  border: 0;
  border-radius: 12px;
  height: 24px;
  line-height: 24px;
  margin: 2px 0;
  min-width: 24px;
  text-align: center;
  white-space: nowrap;
  max-width: 100%;
  width: auto;
  position: absolute;
  top: 4px;
  right: 8px;
  cursor: pointer;
  font-size: $fontSizeSmall;
  font-weight: bold;
  color: var(--color-text-secondary);

  ~ .nav-link-content {
    margin-right: 30px; } }

.nav-link-coin-icon {
  width: 11px; }

.nav-link-coin--alarm {
  background-color: var(--color-danger);
  color: var(--color-text-white); }
