.crossServerBlind {
  .search-bar {
    background-color: var(--color-background);
    margin-bottom: 24px; } }

.crossServerBlind-servers {
  margin: 0 -32px 32px;
  padding: 0 32px;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--color-border);

  .definition {
    display: block;
    margin: 12px 0; } }

.crossServerBlind-server {
  position: relative;
  padding-left: 24px;
  margin: 12px 0;
  min-height: 26px;

  .icon {
    position: absolute;
    height: 18px;
    width: 18px;
    top: 2px;
    left: 0; } }

.crossServerBlind-server-title {
  font-weight: 500;
  line-height: 24px; }

.crossServerBlind-server-detail {
  margin-left: 4px;
  color: var(--color-text-secondary); }

.crossServerBlind-table {
  margin-left: -32px;
  margin-right: -32px;

  .table-content {
    background-color: var(--color-background); }

  .table-title {
    background-color: var(--color-background-card); }

  .table-cell:first-child .table-cell-content {
    padding-left: 32px; } }
