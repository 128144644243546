@import "../vars";

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  flex-flow: column;
  background: $colorJCBlue linear-gradient(to bottom, $colorJCBlue, #21329D 50%, #2A2A9D 100%);
  padding: 32px;

  .card-actions {
    padding-left: 32px;
    align-items: center; }

  .link {
    text-transform: uppercase;
    font-size: $fontSizeSmall;
    font-weight: 500; } }

.login-logo {
  color: white;
  margin-bottom: 32px;
  font-size: 11px;
  width: 300px;

  .logo {
    text-align: center; }

  .logo--custom,
  .logo--custom > svg {
    width: 100%; } }

.login-version {
  font-size: 12px;
  font-weight: 500;
  color: white;
  opacity: .2;
  margin-bottom: 32px; }

.login-icon {
  color: white;
  margin-bottom: 8px;
  display: inline-block; }

.login-form {
  width: 400px;
  margin-bottom: 24px; }
