@import "../vars";

.central-stations {
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start; }

.central-station {
  padding: 24px;
  margin-bottom: 16px;
  margin-right: 16px;
  width: calc(50% - 16px);

  @media screen and (max-width: 1000px) {
    width: 100%; } }

.central-station-header {
  .btn {
    margin-left: 12px; }

  .definition {
    font-size: $fontSizeLarge; }

  .definition-detail {
    font-size: $fontSizeMedium; } }

.central-station-links {
  .empty {
    display: block;
    margin: 16px 0 12px; } }

.central-station-link {
  font-weight: 500;
  margin-right: 16px;
  display: inline-block;
  padding: 0 12px;
  margin-top: 12px;
  height: 30px;
  line-height: 30px;
  border-radius: 15px;
  font-size: $fontSizeSmall;
  text-transform: uppercase;
  background-color: var(--color-background-black5);
  white-space: nowrap; }

.central-edit-link {
  cursor: pointer; }

.trigger {
  font-weight: bold;
  font-size: $fontSizeSmall;
  margin: 0 2px;
  cursor: pointer; }

.trigger--enabled {
  color: #000000; }

.trigger--disabled {
  color: var(--color-disabled); }
