@import "../vars";

.search {
  position: relative;
  z-index: 5;
  height: 36px; }

.search-bar {
  background-color: var(--color-background-card);
  box-shadow: $boxShadowElevation05;
  border-radius: 2px;
  transition: box-shadow .3s $easeOutCubic;
  z-index: 10;

  ::placeholder {
    color: var(--color-text-super-light);
    font-weight: normal; } }

.search--expanded {
  .search-bar {
    box-shadow: $boxShadowElevation2; }

  .search-box {
    border-radius: 2px 2px 0 0;
    box-shadow: inset 0 -1px var(--color-border);

    .btn {
      border-radius: 0 2px 0 0; } } }

.search-box {
  height: 36px;
  box-sizing: border-box;
  padding-left: 42px;
  padding-right: 24px;
  position: relative;
  margin-left: -1px;
  border-radius: 2px; }

.search-box-input {
  width: 100%;
  line-height: 36px; }

.search-box--withIcon {
  padding-right: 60px;

  .btn {
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
    border-radius: 0 2px 2px 0;
    box-shadow: none !important; } }

.search-box-icon {
  position: absolute;
  left: 0;
  top: 0;
  width: 42px;
  height: 36px;
  text-align: center;
  line-height: 36px; }

.search-autoComplete {
  position: relative;
  z-index: 1; }

.search-autoComplete-line {
  height: 36px;
  line-height: 36px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: auto;
  scrollbar-width: thin; }

.search-autoComplete-input {
  flex-grow: 1; }

.search-autoComplete-input-element {
  width: 100%;
  min-width: 200px;
  display: block; }

//.search-content
//height: 36px
//line-height: 36px
//flex-grow: 1
//cursor: text
//align-items: center
//position: relative
//white-space: nowrap

//.search-label
//overflow: auto
//display: flex

.search-autoComplete-item {
  height: 24px;
  line-height: 24px;
  border-right: 1px solid var(--color-border);
  margin-right: 12px;
  padding-right: 12px;
  cursor: pointer; }

.search-autoComplete-name {
  opacity: $opacityTextSecondary;
  margin-right: 4px;
  font-weight: normal;

  &:after {
    content: ':'; } }

.search-autoComplete-value {
  font-weight: 500; }

.search-input {
  flex-grow: 1;
  display: flex; }

.search-autoComplete-clear {
  cursor: pointer;
  position: absolute;
  padding: 0 8px;
  box-sizing: content-box;
  right: 0;
  top: 0;
  color: var(--color-text-secondary);
  text-align: center;
  height: 100%;

  + .search-autoComplete-line {
    margin-right: 30px; } }

.search-filters {
  padding: 24px;
  width: 100%;
  overflow-x: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  max-height: 100vh;
  min-height: 230px;

  .spinner-container {
    height: 230px; } }

.search-filter {
  min-width: 160px;
  margin-right: 32px;
  padding-left: 12px;

  .hint {
    margin-bottom: 12px;
    font-weight: 500; }

  &:last-child {
    margin-right: 0; } }

.search-filter--date {
  min-width: 220px; }

.search-filter-value {
  line-height: 28px;
  height: 28px;
  padding: 0 12px;
  margin-left: -12px;
  border-radius: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  margin-bottom: 4px; }

.search-filter-value--active {
  font-weight: 500;
  color: var(--color-text-highlight);
  background-color: var(--color-background-gray200); }

.search-filter-value--empty {
  color: var(--color-text-secondary); }

.search-autoComplete-popup {
  position: absolute;
  top: 100%;
  margin-left: -24px; }
